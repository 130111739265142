import {A11y, Autoplay, Navigation} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react'
import CardProject from './CardProject'
import React from 'react'

function SliderProjects() {
    return (
        <Swiper
            modules={[Autoplay, Navigation, A11y]}
            navigation={true}
            loop={true}
            autoplay={{
                delay: 5000,
                disableOnInteraction: false,
            }}
            className="rounded-xl"
            speed={1000}
            simulateTouch={false}
            slidesPerView={1}
        >
            <SwiperSlide>
                <CardProject
                    title="Elios Mobility"
                    link="https://eliosmobility.com"
                    categories={['Catalogo', 'Web']}
                    img="https://res.cloudinary.com/giuliozulian/image/upload/v1683352775/elios-cover.webp"
                    subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident."
                />
            </SwiperSlide>
            <SwiperSlide>
                <CardProject
                    title="Marka Abbigliamento"
                    link="https://marka-abbigliamento.it"
                    categories={['Landing Page', 'Wordpress']}
                    img="https://res.cloudinary.com/giuliozulian/image/upload/v1683269205/marka-cover.webp"
                    subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident."
                />
            </SwiperSlide>
            <SwiperSlide>
                <CardProject
                    title="Comunichiamo Impresa"
                    link="https://www.comunichiamoimpresa.it"
                    categories={['Portfolio', 'Wordpress']}
                    img="https://res.cloudinary.com/giuliozulian/image/upload/v1683269205/ci-cover.webp"
                    subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident."
                />
            </SwiperSlide>
            <SwiperSlide>
                <CardProject
                    title="Centro Nico"
                    link="https://centronico.it/"
                    categories={['Portfolio', 'Wordpress']}
                    img="https://res.cloudinary.com/giuliozulian/image/upload/v1683269205/centro-nico-cover.webp"
                    subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident."
                />
            </SwiperSlide>
            <SwiperSlide>
                <CardProject
                    title="X Ingredients"
                    link="https://www.x-ingredients.com/it"
                    categories={['Configurator', 'Typo3']}
                    img="https://res.cloudinary.com/giuliozulian/image/upload/v1683356358/x-ingredients-cover.webp"
                    subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident."
                />
            </SwiperSlide>
            <SwiperSlide>
                <CardProject
                    title="Riccardo Zanetti"
                    link="https://getriccardo.com"
                    categories={['Portfolio', 'Wordpress']}
                    img="https://res.cloudinary.com/giuliozulian/image/upload/v1683269205/getriccardo-cover.webp"
                    subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident."
                />
            </SwiperSlide>
            <SwiperSlide>
                <CardProject
                    title="Galileo Network S.p.A."
                    link="https://www.galileonetwork.it"
                    categories={['Portfolio', 'Wordpress']}
                    img="https://res.cloudinary.com/giuliozulian/image/upload/v1683352776/galileo-cover.webp"
                    subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident."
                />
            </SwiperSlide>
            <SwiperSlide>
                <CardProject
                    title="Themadmen"
                    link="https://themadmen.it/"
                    categories={['Community', 'Courses']}
                    img="https://res.cloudinary.com/giuliozulian/image/upload/v1683352776/themadmen-cover.webp"
                    subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident."
                />
            </SwiperSlide>
        </Swiper>
    )
}

export default SliderProjects
