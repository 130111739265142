import React from 'react'

interface CardProjectProps {
    title: string;
    link?: string;
    subtitle?: string;
    text?: string;
    img?: string;
    categories?: string[];
}
function CardProject(props: CardProjectProps) {
    return (
        <section className="bg-white shadow-xl dark:shadow-none dark:bg-black md:h-[55vh] overflow-hidden">
            <div
                className="relative block h-[38vh] md:h-[30vh] overflow-hidden relative px-6 w-full rounded-t-xl bg-cover bg-no-repeat"
                style={{
                    backgroundImage: `url(${props.img})`,
                }}
                rel="noreferrer"
            >
                <div className="absolute h-full w-full top-0 left-0 bg-gradient-to-b from-black to-transparent z-10 opacity-50"></div>

                <a
                    className="absolute text-light z-20 shadow-xl bg-primary flex w-10 h-10 items-center justify-center rounded-full text-3xl right-4 top-3 md:top-4"
                    href={props.link}
                    target="_blank"
                    rel="noreferrer"
                >
                    <span className="material-symbols-outlined -rotate-45">
                        link
                    </span>
                </a>
                <div className="absolute z-20 text-light bottom-6 left-6">
                    <span className="mb-3 text-base block text-light">
                        Last Projects
                    </span>
                    <h2 className="text-4xl mb-2">{props.title}</h2>
                    <span className="text-md font-light text-neutral-400">
                        {props.categories.map((category, index) => {
                            if (index !== props.categories.length - 1) {
                                return category + ', '
                            } else {
                                return category
                            }
                        })}
                    </span>
                </div>

                <div className="absolute h-full w-full -bottom-20 left-0 bg-gradient-to-t from-black to-transparent z-10 opacity-80"></div>
            </div>
            <article className="p-6 md:flex">
                <div className="w-full md:w-4/12">
                    <p className="dark:text-light/80 text-lg mb-4">
                        {props.subtitle}
                    </p>
                </div>
                <div className="w-full md:w-8/12 md:px-12">
                    <p className="dark:text-light/50 font-light text-sm mb-5">
                        {props.text}
                    </p>
                    <a
                        className="hover:bg-light hover:text-secondary px-4 py-2 bg-primary rounded-md text-sm text-light inline-block"
                        href={props.link}
                        target="_blank"
                        rel="nofollow noreferrer"
                    >
                        Visit website
                    </a>
                </div>

                <div className="mt-4 md:mt-0 md:col-span-1">
                    <span className="material-symbols-outlined text-2xl text-amber-50">
                        favorite
                    </span>
                </div>
            </article>
        </section>
    )
}

export default CardProject
