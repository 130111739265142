import {A11y, Autoplay} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react'
import React from 'react'
import CardTool from './CardTool'

function SliderTools() {
    return (
        <Swiper
            className="h-[60vh] md:h-[56.5vh] w-full"
            modules={[Autoplay, A11y]}
            direction="vertical"
            loop={true}
            autoplay={{
                delay: 2000,
                disableOnInteraction: false,
            }}
            speed={1000}
            simulateTouch={false}
            slidesPerView={8}
        >
            <SwiperSlide>
                <CardTool icon="Github" />
            </SwiperSlide>
            <SwiperSlide>
                <CardTool icon="Docker" />
            </SwiperSlide>
            <SwiperSlide>
                <CardTool icon="Tailwind" />
            </SwiperSlide>
            <SwiperSlide>
                <CardTool icon="React" />
            </SwiperSlide>
            <SwiperSlide>
                <CardTool icon="Vue" />
            </SwiperSlide>
            <SwiperSlide>
                <CardTool icon="Three.js" />
            </SwiperSlide>
            <SwiperSlide>
                <CardTool icon="Wordpress" />
            </SwiperSlide>
            <SwiperSlide>
                <CardTool icon="Typo3" />
            </SwiperSlide>
            <SwiperSlide>
                <CardTool icon="Typescript" />
            </SwiperSlide>
            <SwiperSlide>
                <CardTool icon="Vanilla js" />
            </SwiperSlide>
            <SwiperSlide>
                <CardTool icon="Jquery" />
            </SwiperSlide>
            <SwiperSlide>
                <CardTool icon="Jira" />
            </SwiperSlide>
        </Swiper>
    )
}

export default SliderTools
