import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'

i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    lng: 'en',
    resources: {
        en: {
            translations: require('./../i18n/en/translations.json'),
        },
        it: {
            translations: require('./../i18n/it/translations.json'),
        },
    },
    ns: ['translations'],
    defaultNS: 'translations',
})

i18n.languages = ['en', 'it']

export default i18n
