import {configureStore} from '@reduxjs/toolkit'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import rootReducer from './rootReducer.ts'

const store = configureStore({
    reducer: rootReducer,
})

export default store
