import React, {useState} from 'react'

function AudioPlayer() {
    const [isPlaying, setIsPlaying] = useState(false)
    const audioRef = React.createRef()

    const togglePlay = () => {
        const audio = audioRef.current
        if (isPlaying) {
            audio.pause()
        } else {
            audio.play()
        }
        setIsPlaying(!isPlaying)
    }

    return (
        <div className="dark:text-light absolute bottom-12 md:bottom-auto left-1/2 -translate-x-1/2 mx-auto">
            <audio
                ref={audioRef}
                src="https://res.cloudinary.com/giuliozulian/video/upload/v1683293032/mercy.mp3"
            />
            <button
                className={`${
                    isPlaying
                        ? 'is-playing text-primary'
                        : 'pause text-secondary'
                } cursor-pointer`}
                onClick={togglePlay}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                >
                    <rect
                        className="eq-bar eq-bar--1"
                        x="4"
                        y="4"
                        width="3.7"
                        height="8"
                    />
                    <rect
                        className="eq-bar eq-bar--2"
                        x="10.2"
                        y="4"
                        width="3.7"
                        height="16"
                    />
                    <rect
                        className="eq-bar eq-bar--3"
                        x="16.3"
                        y="4"
                        width="3.7"
                        height="11"
                    />
                </svg>
            </button>
        </div>
    )
}

export default AudioPlayer
