import React from 'react'

interface CardToolProps {
    link: string;
    title: string;
}
function CardArticle(props: CardToolProps) {
    return (
        <a
            href={props.link}
            target="_blank"
            rel="noreferrer"
            className="text-light/80 block mb-2.5 text-base underline hover:text-primary underline-offset-4 focus:text-secondary"
        >
            {props.title}
        </a>
    )
}

export default CardArticle
