import * as React from 'react'

import App from './App'
import reportWebVitals from './reportWebVitals'
import {createRoot} from 'react-dom/client'
import {CookiesProvider} from 'react-cookie'
import {Provider} from 'react-redux'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from './store/store.ts'
import './i18n/config'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    <Provider store={store}>
        <CookiesProvider>
            <App />
        </CookiesProvider>
    </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
