import './style.scss'
import React, {useState} from 'react'
import Card from './components/Card'
import Footer from './components/Footer'
import Header from './components/Header'
import Loader from './components/Loader'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css'
import SliderProjects from './components/SliderProjects'
import SliderTools from './components/SliderTools'
import SliderCourses from './components/SliderCourses'
import SliderMovie from './components/SliderMovie'
import SliderArticles from './components/SliderArticles'
import {ReactInternetSpeedMeter} from 'react-internet-meter'
import 'react-internet-meter/dist/index.css'

export default function App() {
    const [network, setNetwork] = useState(0)

    return (
        <main className="min-h-screen flex flex-col bg-neutral-100 dark:bg-dark">
            <Header />

            <div className="grid grid-cols-12 gap-3 px-2 pt-12 mb-4">
                <div className="order-1 md:order-0 col-span-9 md:col-span-3 overflow-y-scroll grid grid-cols-12 gap-y-3 md:h-[55vh]">
                    <Card
                        title="About me"
                        icon="downloading"
                        text="
                I've always been passionate about IT and design, and for over six
years I've been developing and designing tailor-made, dynamic and
creative websites and applications, taking care of each project in
terms of graphic layout (optimised for all recent devices and not),
code, search engine optimisation, micro-animations to improve the
user experience with a particular focus on performance.
I have no difculty in working in a team, collaboration is crucial.
I have consolidate communication skills and can easily adapt to new
working contexts.
            "
                    />
                    <Card title="Skills" icon="bar_chart" />
                    <Card title="Software" icon="build" />
                    <Card title="Hobbies and interests" icon="interests" />
                    <Card title="Certifications" icon="preliminary" />
                </div>
                <div className="order-2 overflow-hidden md:order-1 col-span-3 md:col-span-1 relative flex justify-center">
                    <div className="absolute h-12 w-full -top-4 left-0 bg-gradient-to-b dark:from-dark to-transparent z-10 opacity-100"></div>
                    <SliderTools />
                    <div className="absolute h-12 w-full bottom-0 left-0 bg-gradient-to-t dark:from-dark to-transparent z-10 opacity-100"></div>
                </div>
                <div className="order-0 md:order-2 col-span-12 md:col-span-8 md:col-start-5 rounded-xl">
                    <SliderProjects />
                </div>
                <div className="order-3 md:order-3 col-span-12 md:col-span-3 h-[25vh] relative rounded-xl overflow-hidden">
                    <SliderCourses />
                    <span className="absolute z-10 text-light/40 right-4 top-4 text-sm">
                        Last courses
                    </span>
                </div>
                <div className="order-4 md:order-4 col-span-12 md:col-span-3 h-[25vh] relative rounded-xl overflow-hidden">
                    <SliderMovie />
                    <span className="absolute z-10 text-light/40 right-4 top-4 text-sm">
                        Movie/Series
                    </span>
                </div>
                <div className="order-5 md:order-5 col-span-12 md:col-span-2 h-[25vh] dark:shadow-3xl px-2 relative rounded-lg overflow-hidden">
                    <h3 className="text-xl mt-2 text-light/30 font-light block mb-4">
                        Last articles read
                    </h3>
                    <SliderArticles />
                    <div className="absolute h-12 w-full bottom-0 left-0 bg-gradient-to-t from-dark to-transparent z-10 opacity-100"></div>
                </div>
                <div className="order-7 md:order-7 col-span-12 md:col-span-1 dark:shadow-3xl flex h-[25vh] relative rounded-lg overflow-hidden">
                    <div className="w-full flex flex-col justify-start mt-0">
                        <ReactInternetSpeedMeter
                            customClassName={null}
                            txtSubHeading=""
                            outputType=""
                            txtMainHeading="Opps..."
                            pingInterval={500} // milliseconds
                            thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
                            threshold={100}
                            imageUrl="https://www.shutterstock.com/image-vector/business-network-vector-icon-600w-396818794.jpg"
                            downloadSize="1781287" //bytes
                            callbackFunctionOnNetworkDown={(speed) =>
                                console.log(`Internet speed is down ${speed}`)
                            }
                            callbackFunctionOnNetworkTest={(speed) =>
                                setNetwork(speed)
                            }
                        />
                        <h2 className="inline-flex flex-col justify-center bg-primary/10 border border-primary text-light py-3.5 px-4 rounded-lg">
                            <span className="text-sm block text-center text-light/50 font-light">
                                Bandwidth:
                            </span>
                            <span className="text-base text-center">
                                {navigator.onLine ? `${network} MB` : 'Offline'}
                            </span>
                        </h2>
                        <h2 className="inline-flex mt-4 flex-col justify-center bg-primary/10 border border-primary text-light py-3.5 px-4 rounded-lg">
                            <span className="text-sm block text-center text-light/50 font-light">
                                Platform:
                            </span>
                            <span className="text-base text-center">
                                {navigator.userAgentData
                                    ? navigator.userAgentData.platform
                                    : 'MacOs'}
                            </span>
                        </h2>
                    </div>
                </div>
                <div className="order-6 md:order-8 col-span-12 md:col-span-2 dark:shadow-3xl h-[25vh] relative rounded-lg overflow-hidden">
                    <div className="inline-flex flex-col w-full">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://wa.me/3496629234"
                            className="border-2 rounded-md mb-2.5 py-2 bg-primary/50 hover:bg-primary text-center px-4 text-light text-lg border-primary/30"
                        >
                            Code consultancy 👋
                        </a>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://wa.me/3496629234"
                            className="border-2 rounded-md mb-2.5 py-2 bg-primary/50 hover:bg-primary text-center px-4 text-light text-lg border-primary/30"
                        >
                            WCAG Accessibility 🔥
                        </a>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://wa.me/3496629234"
                            className="border-2 rounded-md mb-2.5 py-2 bg-primary/50 hover:bg-primary text-center px-4 text-light text-lg border-primary/30"
                        >
                            Responsive design 🎨
                        </a>
                    </div>
                </div>
            </div>

            <Footer />

            <Loader />
        </main>
    )
}
