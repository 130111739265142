function Header() {
    return (
        <header className="fixed top-0 z-50 w-full dark:text-light px-6 pb-10 flex justify-center">
            <span className="hidden md:block bg-red-500 w-3 h-3 absolute rounded-full top-3.5 left-4"></span>
            <span className="hidden md:block bg-yellow-500 w-3 h-3 absolute rounded-full top-3.5 left-9"></span>
            <span className="hidden md:block bg-green-500 w-3 h-3 absolute rounded-full top-3.5 left-14"></span>
            <div className="absolute z-50 flex items-center mx-auto">
                <h1 className="absolute text-lg font-bold right-full -translate-x-3 w-64 text-right top-2 md:top-2.5">
                    GIULIO ZULIAN
                </h1>
                <a
                    href="/"
                    className="border-2 border-primary/60 bg-primary shadow-2xl inline-block p-4 items-center justify-center rounded-full"
                >
                    <svg
                        width="32"
                        height="32"
                        className="opacity-0"
                        viewBox="0 0 119 119.398499"
                        version="1.1"
                        fill="black"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                        <defs>
                            <circle
                                id="path-1"
                                cx="98.5083876"
                                cy="15.7457326"
                                r="12.5"
                            ></circle>
                            <filter
                                x="-12.0%"
                                y="-12.0%"
                                width="124.0%"
                                height="124.0%"
                                filterUnits="objectBoundingBox"
                                id="filter-2"
                            >
                                <feMorphology
                                    radius="2"
                                    operator="erode"
                                    in="SourceAlpha"
                                    result="shadowSpreadInner1"
                                ></feMorphology>
                                <feGaussianBlur
                                    stdDeviation="1"
                                    in="shadowSpreadInner1"
                                    result="shadowBlurInner1"
                                ></feGaussianBlur>
                                <feOffset
                                    dx="2"
                                    dy="2"
                                    in="shadowBlurInner1"
                                    result="shadowOffsetInner1"
                                ></feOffset>
                                <feComposite
                                    in="shadowOffsetInner1"
                                    in2="SourceAlpha"
                                    operator="arithmetic"
                                    k2="-1"
                                    k3="1"
                                    result="shadowInnerInner1"
                                ></feComposite>
                                <feColorMatrix
                                    values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.338805726 0"
                                    type="matrix"
                                    in="shadowInnerInner1"
                                ></feColorMatrix>
                            </filter>
                        </defs>
                        <g
                            id="Page-1"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                        >
                            <g
                                id="Artboard"
                                transform="translate(-59.890410, -251.107463)"
                            >
                                <g
                                    id="Group"
                                    transform="translate(60.890410, 252.107463)"
                                >
                                    <rect
                                        id="Rectangle"
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeLinejoin="bevel"
                                        x="-0.5"
                                        y="41.8984993"
                                        width="118"
                                        height="34"
                                        rx="17"
                                    ></rect>
                                    <rect
                                        id="Rectangle"
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeLinejoin="bevel"
                                        x="18.5"
                                        y="83.8984993"
                                        width="99"
                                        height="34"
                                        rx="17"
                                    ></rect>
                                    <rect
                                        id="Rectangle"
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeLinejoin="bevel"
                                        x="38.4264706"
                                        y="-0.5"
                                        width="79"
                                        height="34"
                                        rx="17"
                                    ></rect>
                                    <g id="Oval">
                                        <use
                                            className="fill-primary stroke-primary"
                                            strokeWidth="2px"
                                            fillRule="evenodd"
                                            xlinkHref="#path-1"
                                        ></use>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </a>
                <h2 className="absolute left-full text-sm font-light w-64 left-[78px] top-2.5 md:top-3.5">
                    <span className="hidden md:inline-block">Creative </span>
                    <span className="text-light px-1 py-0.5 ml-1 rounded-md bg-primary/50">
                        {' '}
                        frontend developer
                    </span>
                </h2>
            </div>
            <div className="list-cta-container absolute right-4 top-1.5 md:top-2.5 flex items-center">
                <a
                    className="hidden md:flex text-xl flex items-center "
                    href="mailto:giuliozulian@gmail.com"
                >
                    👋
                    <span className="text-sm ml-2">get in touch</span>
                </a>
                <a
                    className="hidden md:flex text-xl ml-6 items-center"
                    target="_blank"
                    href="https://tally.so/r/mKz7Rg"
                    rel="noreferrer"
                >
                    🚀
                    <span className="text-sm mx-2">have you a project?</span>
                </a>
            </div>
        </header>
    )
}

export default Header
