import {createSlice, PayloadAction} from '@reduxjs/toolkit'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {RootState} from '../../store/rootReducer.ts'

export const generalSlice = createSlice({
    name: 'general',
    initialState: {
        aboutModalVisible: false,
    },
    reducers: {
        setAboutModalVisible(
            state: RootState,
            action: PayloadAction<boolean>
        ): void {
            state.aboutModalVisible = action.payload
        },
    },
})

export const {setAboutModalVisible} = generalSlice.actions
