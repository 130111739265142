import {combineReducers} from '@reduxjs/toolkit'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {generalSlice} from '../features/general/generalSlice.ts'

const rootReducer = combineReducers({
    general: generalSlice.reducer,
})

export default rootReducer
export type RootState = ReturnType<typeof rootReducer>
