import React from 'react'

interface CardProjectProps {
    title: string;
    link?: string;
    subtitle?: string;
    text?: string;
    video: string;
    categories?: string[];
}
function CardExtra(props: CardProjectProps) {
    return (
        <div className="h-full w-full overflow-hidden">
            <video
                preload="metadata"
                className="video-preview bg-dark rounded absolute aspect-video rounded-lg  top-0 w-full object-cover object-center h-full"
                width="100%"
                id="video-journey"
                playsInline
                loop={true}
                muted={true}
                autoPlay={true}
                height="100%"
                src={props.video}
            ></video>
            <div className="absolute h-full w-full bottom-0 left-0 bg-gradient-to-t from-black to-transparent opacity-60"></div>
            {props.title ? (
                <span className="absolute bottom-5 left-4 text-light text-lg">
                    {props.title}

                    {props.subtitle ? (
                        <span className="text-light/80 text-xs">
                            {' / '} {props.subtitle}
                        </span>
                    ) : undefined}
                </span>
            ) : undefined}
        </div>
    )
}

export default CardExtra
