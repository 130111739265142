import {A11y, Autoplay} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react'
import React from 'react'
import CardArticle from './CardArticle'

function SliderArticles() {
    return (
        <Swiper
            className="h-[60vh] md:h-[35vh] w-full"
            modules={[Autoplay, A11y]}
            direction="vertical"
            loop={true}
            autoplay={{
                delay: 2000,
                disableOnInteraction: false,
            }}
            speed={1000}
            spaceBetween={0}
            simulateTouch={false}
            slidesPerView={4}
        >
            <SwiperSlide>
                <CardArticle
                    title="Best practices for building accessible React apps"
                    link="https://aishwarya2593.medium.com/best-practices-for-building-accessible-react-apps-6bd29b8ecdbe"
                />
            </SwiperSlide>
            <SwiperSlide>
                <CardArticle
                    title="Ten things for my museum colleagues working in digital"
                    link="https://medium.com/@sebchan/ten-things-for-my-museum-colleagues-working-in-digital-from-mgaconf2018-fade367ad93d"
                />
            </SwiperSlide>
            <SwiperSlide>
                <CardArticle
                    title="5 Skills You Need as a Frontend Developer in 2023"
                    link="https://medium.com/@pearlie/5-skills-you-need-as-a-frontend-developer-in-2023-6fdfb75f0b0c"
                />
            </SwiperSlide>
        </Swiper>
    )
}

export default SliderArticles
