import {A11y, Autoplay, Pagination, Keyboard} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react'
import CardExtra from './CardExtra'
import React from 'react'

function SliderMovie() {
    return (
        <Swiper
            className="absolute rounded-xl w-full h-full overflow-hidden"
            modules={[Autoplay, Pagination, Keyboard, A11y]}
            loop={true}
            autoplay={{
                delay: 8000,
                disableOnInteraction: false,
            }}
            keyboard={true}
            pagination={true}
            slidesPerView={1}
        >
            <SwiperSlide>
                <CardExtra
                    title="Breaking Bad"
                    link="https://marka-abbigliamento.it"
                    categories={['Landing Page', 'Wordpress']}
                    video="https://res.cloudinary.com/giuliozulian/video/upload/v1/video/movie-tv/breaking-bad-compressed"
                />
            </SwiperSlide>
            <SwiperSlide>
                <CardExtra
                    title="Suits"
                    link="https://marka-abbigliamento.it"
                    categories={['Landing Page', 'Wordpress']}
                    video="https://res.cloudinary.com/giuliozulian/video/upload/v1/video/movie-tv/suits-compressed"
                />
            </SwiperSlide>
            <SwiperSlide>
                <CardExtra
                    title="Sherlock"
                    link="https://marka-abbigliamento.it"
                    categories={['Landing Page', 'Wordpress']}
                    video="https://res.cloudinary.com/giuliozulian/video/upload/v1/video/movie-tv/sherlock-compressed"
                />
            </SwiperSlide>
            <SwiperSlide>
                <CardExtra
                    title="Harry Potter Saga"
                    link="https://marka-abbigliamento.it"
                    categories={['Landing Page', 'Wordpress']}
                    video="https://res.cloudinary.com/giuliozulian/video/upload/v1/video/movie-tv/harry-potter-compressed"
                />
            </SwiperSlide>
        </Swiper>
    )
}

export default SliderMovie
